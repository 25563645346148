import React, { useState } from "react";
import "./nav.css";
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BiBook, BiMessageSquareDetail, BiBookHeart } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";
import { RiPaypalFill } from "react-icons/ri";
import { RiHeartLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const navigate = useNavigate();
  const [activeNav, setActiveNav] = useState("#");

  const navigateToIndexWithAnchor = (anchor) => {
    navigate("/" + anchor);
    setActiveNav(anchor); // Setze den aktiven Zustand
  };

  return (
    <>
      <div className="buy-coffe">
        <a
          href="https://paypal.me/rexhamergim?country.x=DE&locale.x=de_DE"
          target="_blank"
          rel="noopener noreferrer"
          className="coffee-button"
        >
           Buy Me a Coffee <RiHeartLine /><RiPaypalFill />
        </a>
      </div>

      <nav>
        <a
          href="#mbox-splitter"
          onClick={() => navigateToIndexWithAnchor("#mbox-splitter")}
          className={activeNav === "#mbox-splitter" ? "active" : ""}
        >
          <AiOutlineHome />
        </a>
        <a
          href="#about"
          onClick={() => navigateToIndexWithAnchor("#about")}
          className={activeNav === "#about" ? "active" : ""}
        >
          <BiBook />
        </a>
        <a
          href="/blog"
          onClick={() => {
            navigate("/blog");
            setActiveNav("/blog");
          }}
          className={activeNav === "/blog" ? "active" : ""}
        >
          <BiBookHeart />
        </a>
        <a
          href="#data-information"
          onClick={() => navigateToIndexWithAnchor("#data-information")}
          className={activeNav === "#data-information" ? "active" : ""}
        >
          <RiServiceLine />
        </a>
        <a
          href="#creation"
          onClick={() => navigateToIndexWithAnchor("#creation")}
          className={activeNav === "#creation" ? "active" : ""}
        >
          <AiOutlineUser />
        </a>
        <a
          href="#contact"
          onClick={() => navigateToIndexWithAnchor("#contact")}
          className={activeNav === "#contact" ? "active" : ""}
        >
          <BiMessageSquareDetail />
        </a>
      </nav>
    </>
  );
};

export default Nav;
